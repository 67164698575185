<script setup lang="ts">
import ModalGetNotified from './ModalGetNotified.vue'
import { solutions } from '~/utils/solutions'
import Notification from '~/pages/components/new/Notification.vue'

defineProps<{
    card?: boolean
}>()
const showModal = ref(false)
const showNotification = ref(false)

const product = ref('Leg')

function onGetNotified(p: string) {
    console.log('onGetNotified', p)
    showNotification.value = false
    product.value = p
    showModal.value = true
}

function onSubmitted() {
    showNotification.value = true
    showModal.value = false
}

const settings = useLandingPageSettings()

</script>

<template>
    <div>
        <div class="py-24 sm:py-32" :class="[card ? 'bg-green-50' : '']">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div :class="[card ? 'mx-auto max-w-2xl lg:text-center' : '']">
                    <h2
                        class="text-base font-semibold leading-7 text-green-700"
                    >
                        {{ settings.solutionsEyebrow }}
                    </h2>
                    <p
                        class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                    >
                        {{ settings.solutionsTitle }}
                    </p>
                    <p class="mt-6 text-lg leading-8 text-gray-600">
                        {{ settings.solutionsSubtitle }}
                    </p>
                </div>
                <!-- <div
                    class="mx-auto mt-16 max-w-2xl sm:mt-20"
                    :class="[card ? 'lg:mt-24 lg:max-w-none' : '']"
                /> -->
                <!-- <dl
                    class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-12 xl:gap-x-16"
                    :class="[card ? '' : '']"
                >
                    <div
                        v-for="solution in solutions"
                        :key="solution.name"
                        class="flex flex-col"
                    >
                        <dt
                            class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"
                        >
                            <component
                                :is="solution.icon"
                                class="h-5 w-5 flex-none text-green-600"
                                aria-hidden="true"
                            />
                            <NuxtLink
                                :to="solution.href ?? '#'"
                                class="hover:cursor-pointer hover:text-green-700"
                            >
                                {{ solution.name }}
                            </NuxtLink>
                        </dt>
                        <dd
                            class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600"
                        >
                            <p class="flex-auto">
                                {{ solution.description }}
                            </p>
                            <p class="mt-6">
                                <NuxtLink
                                    v-if="solution.href"
                                    :to="solution.href"
                                    class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                >
                                    Learn more
                                    <span aria-hidden="true">→</span>
                                </NuxtLink>
                                <button
                                    v-else
                                    type="button"
                                    class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    @click="onGetNotified(solution.name)"
                                >
                                    Get notified
                                </button>
                            </p>
                        </dd>
                    </div>
                </dl> -->
            </div>
        </div>
        <ModalGetNotified
            v-model:open="showModal"
            :product="product"
            @submitted="onSubmitted()"
            :key="product"
        />
        <Notification
            v-model:show="showNotification"
            notification-type="success"
            title="Request received!"
            :subtitle="`We will notify you as we get ready to launch ${product}.`"
        />
    </div>
</template>
