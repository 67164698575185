<script setup lang="ts">
import { BellAlertIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { DialogTitle } from '@headlessui/vue'
import BaseModal from '~/pages/components/BaseModal.vue'
import { WebsiteInterestFormSubmitDocument } from '~/gql/graphql'
import { graphql } from '~/gql'

const props = defineProps<{
    product: string
    open: boolean
}>()

const emit = defineEmits(['update:open', 'submitted'])

function onClose() {
    emit('update:open', false)
}

const title = 'Get notified'
const subtitle = `${props.product} is currently in beta. Provide your email to receive a preview and get notified when it launches.`

graphql(`
    mutation WebsiteInterestFormSubmit(
        $input: WebsiteInterestFormSubmissionInput!
    ) {
        websiteInterestFormSubmitted(input: $input) {
            done
        }
    }
`)

const { mutate: submitForm } = useMutation(WebsiteInterestFormSubmitDocument, {
    clientId: 'default',
})

const submitting = ref(false)

async function doSubmitForm(e: Event) {
    e.preventDefault()
    const fullName = document.getElementById('full-name') as HTMLInputElement
    const email = document.getElementById('email-address') as HTMLInputElement

    submitting.value = true
    await submitForm({
        input: {
            email: email.value,
            fullName: fullName.value,
            requestType: props.product,
        },
    })
    submitting.value = false
    emit('submitted')
    emit('update:open', false)
}
</script>

<template>
    <BaseModal :open="open" class="relative" @update:open="onClose">
        <div
            v-if="submitting"
            class="absolute right-0 top-0 flex h-full w-full items-center justify-center bg-white bg-opacity-60"
        >
            <span class="loading loading-spinner loading-md align-middle" />
        </div>
        <div class="absolute right-0 top-0 pr-4 pt-4 sm:block">
            <button
                type="button"
                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                @click="onClose()"
            >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
        <div>
            <div class="items-start">
                <div class="flex sm:flex-col">
                    <div
                        class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                    >
                        <BellAlertIcon
                            class="h-6 w-6 text-green-600"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="flex-1 px-4 sm:mt-5 sm:text-center">
                        <DialogTitle
                            as="h3"
                            class="text-base font-semibold leading-6 text-gray-900"
                        >
                            {{ title }}
                        </DialogTitle>
                        <div class="mt-3 text-left">
                            <p class="text-sm text-gray-500">
                                {{ subtitle }}
                            </p>
                        </div>
                    </div>
                </div>

                <form class="mt-6 flex flex-col" @submit="doSubmitForm">
                    <div class="flex flex-col gap-y-3">
                        <div>
                            <input
                                id="full-name"
                                type="text"
                                name="first-name"
                                required="true"
                                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter your name"
                            >
                        </div>
                        <div>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autocomplete="email"
                                required
                                class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Your email"
                            >
                        </div>
                    </div>

                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="submit"
                            class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        >
                            Notify me
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </BaseModal>
</template>
